

import libUmum from '../../library/umum'


export default {
  data() {
    return {
      

      form : {
        id : '',
        uraian : '',
        keterangan : '',
      },
      peserta : {
        jenisapel : '',
        unit_kerja : []
      },
     
      list_data : [],
      list_unitKerja : [],
      list_peserta : [],


      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,

      page_first1: 1,
      page_last1: 0,
      cari_value1: "",
      data_batas1 : 10,
      cek_load_data1 : true,

      page_first2: 1,
      page_last2: 0,
      cari_value2: "",
      cek_load_data2 : true,

      cari_value_peserta: "",
      cek_load_data_peserta : true,

      getAllUnitKerja : false,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_chat : false,
      mdl_maps : false,
      btn_add: false,
      mdl_opd : false,
      mdl_img : false,
    }
  },
  methods: {


    getView : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_apelJenis + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;

              this.cek_load_data = false;
      });
    },


    addData : function(number) {

      fetch(this.$store.state.url.URL_apelJenis + "Add", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
      // console.log(this.form.jeniskategorilokasi)
    },


    editData : function(){
 
      fetch(this.$store.state.url.URL_apelJenis + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(data){
      fetch(this.$store.state.url.URL_apelJenis + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },


    selectData : function(data){
        this.form.id = data.id;
        this.form.uraian = data.uraian;
        this.form.keterangan = data.keterangan;

        this.peserta.jenisapel = data.id;
    },

    addPeserta : function(){
      console.log(this.peserta.unit_kerja)

      fetch(this.$store.state.url.URL_apelJenis + "addUPesertaUnitKerja", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.peserta)

      }).then(res_data => {
          this.Notify('Sukses menambah Peserta', 'primary', 'check_circle_outline');
          this.getViewPeserta();
          this.getView();
      });
    },

    getViewPeserta : function(){
      this.cek_load_data_peserta = true;
      fetch(this.$store.state.url.URL_apelJenis + "viewPesertaUnitKerja", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            id : this.form.id,
            cari_data : this.cari_value_peserta
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_peserta = res_data;
              this.cek_load_data_peserta = false;
              console.log(res_data)
      });
    },

    // ====================================== TAMBAHAN ====================================

      getUnitKerja : function(){
        this.cek_load_data1 = true;

        if (this.peserta.unit_kerja.length <= 0) {
          this.getAllUnitKerja = false;
        } 



        fetch(this.$store.state.url.URL_apelJenis + "selectUnitKerja", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first1,
                cari_value: this.cari_value1,
                data_batas: this.data_batas1
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_unitKerja = res_data.data;
                this.page_last1 = res_data.jml_data;

                this.cek_load_data1 = false;
                // console.log(this.list_unitKerja)
        });
      },


      selecAllUnitKerja : function(){
        if (this.getAllUnitKerja) {
          this.list_unitKerja.forEach(data => {
            this.peserta.unit_kerja.push(data.id)
          });
        } else {
          this.peserta.unit_kerja = [];
        }
      },


    // ====================================== TAMBAHAN ====================================

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            if(this.page_first>1){this.page_first--}
            else{this.page_first = 1;}
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){this.page_first == this.page_last}
            else{this.page_first++;}
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1; this.getView();
        },

        btn_prev1 : function(){
            if(this.page_first1>1){this.page_first1--}
            else{this.page_first1 = 1;}
            this.getUnitKerja();
        },

        btn_next1 : function(){
            if(this.page_first1 >= this.page_last1){this.page_first1 == this.page_last1}
            else{this.page_first1++;}
            this.getUnitKerja();
        },

        cari_data1 : function(){
            this.page_first1 = 1; this.getUnitKerja();
        },


        btn_prev2 : function(){
            if(this.page_first2>1){this.page_first2--}
            else{this.page_first2 = 1;}
            this.getView();
        },

        btn_next2 : function(){
            if(this.page_first2 >= this.page_last2){this.page_first2 == this.page_last2}
            else{this.page_first2++;}
            this.getView();
        },

        cari_data2 : function(){
            this.page_first2 = 1; this.getView();
        },




    // ====================================== PAGINATE ====================================







  },

  mounted () {

    this.getView();
    this.$getLocation()
    .then(coordinates => {
      this.form.lat = coordinates.lat;
      this.form.lng = coordinates.lng;

      this.$store.state.coordinat.lat = coordinates.lat;
      this.$store.state.coordinat.lng = coordinates.lng;
    });

    this.$store.commit("listJeniskategorilokasi");
    this.$store.commit("getStorage");

    this.form.unit_kerja = this.$store.state.unit_kerja





  },
}
